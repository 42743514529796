import {Link} from 'react-router-dom';
import React from "react"
import { render } from "react-dom";
import Accordion from "../Accordion";
import ReactPlayer from "react-player";


import GatorsAboutMeCelebrationSquiggles from "./images/GatorsAboutMeCelebrationSquiggles.png";

function For_theeducators(){
    return(
        <div style={{	
            backgroundImage: `url(${GatorsAboutMeCelebrationSquiggles})`,
            background: 'cover',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "10%",
            width: "100%",
            overflowX: "hidden",
          backgroundSize: '40%',
          textAlign: "left"
            }}>
        <div style = {{padding: "2%"}}></div>
        <div>
     

    </div>
<div style = {{ display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "80%",
	overflowX: "hidden",
  backgroundSize: '75%',
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  border: ".1em solid",
  borderRadius: "2%",
  padding: "2%",
  marginRight: "8%",
  marginLeft: "9%",
  textAlign: "left",


  }}>        
  <h1> About The Toolkit</h1>
 
<div style = {{textAlign: "left"}}>

       <i><p><b> The News Literacy Toolkit won first place overall in the national 2022 Reynolds Journalism Institute's Student Innovation Competition.</b></p></i> 
      <p> Hi! My name is Dana Cassidy. I'm a senior studying journalism and artificial intelligence at the University of Florida and full-stack developer.
        </p>
        <p>
            I designed, built, wrote, and launched this project by myself as a finalist submission in the <a style= {{textDecoration: "underline"}} href= "https://rjionline.org/studentinnovationcompetition/" >Reynolds Journalism Institute's 2022 Student Innovation Competition</a>. 
            The aim of the competition is to improve news literacy in communities in an innovative way. 
        </p>
        <p> I'm a firm believer that news literacy is a systemic problem that needs to be addressed at a young age. I recieved my K-12 education at public schools,
            and never learned what journalism was until I began university. I realized that a lot of doubt, hesitancy, and misinformation is rooted
            in a lack of news media comprehension. Children
            recieve a foundation in civics from a young age, and I belive that media literacy should be a part of that curriculum.
        </p>
        <p>
            As someone born in 2000, I was raised on computers and using digital media to supplement my education. Having an application that's web and mobile friendly
            with games felt like the best medium for accessibility and enjoyability. The News Literacy Toolkit, I hope, will be used as a supplementary service
            to lesson plans and activities, similar to sites like BrainPOP and Khan Academy. 
        </p>
        <p> I wrote the content in a way that's digestible for a majority of audiences, but primarily younger ones. 
            
            I built this website from scratch primarily using ReactJS. If you have questions about my source code and development, please feel free to check out
            the GitHub repository for the project. </p>

            <b  style = {{textAlign: "left"}}><p><a href= "https://github.com/danacassidy/newsliteracytoolkit" style= {{textAlign: "left", color: "blue"}} >View the GitHub repository here.</a></p></b>

            
            <p> Outside of this project, I've worked at the Miami Herald, Orlando Sentinel, and The Palm Beach Post as a reporter and developer on a variety of desks and beats. 
            During the school year, I've served as a managing editor at The Independent Florida Alligator, and I work
            as a researcher, studying information access as well as the intersection of technology and media trust. I also serve as a student advisor
            for both the journalism department and the College of Journalism and Communications at the University of Florida. After graduating in May 2022, I'll be working
            at The Washington Post as a News Engineering intern.
        </p>

        <p>
            Questions? Suggestions? Feel free to email me at danacassidy1@gmail.com. 
        </p>

        </div>
            </div>
            <div style = {{padding: "2%"}}></div>

            
      </div>
    );
}
export default For_theeducators;