import {Link} from 'react-router-dom';
import React from "react"

import DarkBlueCelebrationSquiggles from "./images/DarkBlueCelebrationSquiggles.png";



function News_for_kids(){
	return(
		
		<div style = {{backgroundImage: `url(${DarkBlueCelebrationSquiggles})`, background: 'cover',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: '60%'
		}}>
		<div style = {{padding: "2%"}}></div>
		<div style = {{ display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "70%",
		overflowX: "hidden",
	  backgroundSize: '75%',
	  backgroundColor: "rgba(255, 255, 255, 0.8)",
	  border: ".1em solid",
	  borderRadius: "2%",
	  padding: "5%",
	  fontSize: "70%"
	  }}>
		  <div style = {{textAlign: "center", paddingLeft: "10%", paddingRight: "10%"}}>
		  <h1> NEWS FOR KIDS</h1>
		  <p style= {{textAlign:"left"}}> Want more? Here are external links to resources where you can find news that's geared for kids. Each source is filled with
			  fascinating content about science, art, inventions, and more! Sources are also approved and utilized by educators
			  across the world.
		  </p>
		  <p style= {{textAlign:"left"}}> All websites are safe and approved for school usage. </p>

		<div style= {{border: ".1em solid", width: "75%", marginLeft: "7%",
		borderRadius: "2%", padding: "5%", textAlign: "center", justifyContent: "center", alignSelf:"center", backgroundColor: "rgba(255, 255, 255, 0.4)",
		}}>
				<h1> News-O-Matic</h1>
                    <p  style= {{textAlign:"left"}}> Fun, interactive resource for kids grades kindergarten through eighth. Publishing every weekday, News-O-matic shares
						interactive daily news stories that improve media literacy, societal awareness, and critical thinking in the digital age. </p>
                            <div className='continueButton' style= {{backgroundColor: "#64f7f7", color: "#F2B47E"}}>
                            <a href="https://www.newsomatic.org/" target="_blank" >Head to <b>News-O-Matic</b></a>
            			</div>
		</div>
		<div style = {{padding: "2%"}}></div>

		<div style= {{border: ".1em solid", width: "75%", marginLeft: "7%",
		borderRadius: "2%", padding: "5%", textAlign: "center", justifyContent: "center", alignSelf:"center", backgroundColor: "rgba(255, 255, 255, 0.4)",
		}}>
				<h1> NewsForKids.net</h1>
                    <p style= {{textAlign:"left"}}> A news site geared for kids that shares articles and current events in an easy-to-read, digestible way. The site is filled
						with fascinating, exciting news articles with lots of multimedia! </p>
                            <div className='continueButton' style= {{backgroundColor: "#F2B47E", color: "#64f7f7"}}>
                            <h3 style = {{textAlign:"center"}} ><a href="https://newsforkids.net/" target="_blank" >Head to <b>NewsForKids.net</b></a></h3>
            			</div>
		</div>
		<div style = {{padding: "2%"}}></div>
			
		<div style= {{border: ".1em solid", width: "75%", marginLeft: "7%",
		borderRadius: "2%", padding: "5%", textAlign: "center", justifyContent: "center", alignSelf:"center", backgroundColor: "rgba(255, 255, 255, 0.4)",
		}}>
				<h1> KidsPost</h1>
                    <p style= {{textAlign:"left"}}> The kids section of The Washington Post that's geared for students from second to seventh grade. It appears
                        in it's Sunday paper and gives students a summary about what's happening around the world, in the U.S., and even locally! </p>
                            <div className='continueButton' style= {{color: "#14A6A6"}}>
                            <a href="https://www.washingtonpost.com/lifestyle/kidspost/" target="_blank" >Head to the <b>KidsPost</b></a>
            			</div>
		</div>
		<div style = {{padding: "2%"}}></div>

		<div style= {{border: ".1em solid", width: "75%", marginLeft: "7%",
		borderRadius: "2%", padding: "5%", textAlign: "center", justifyContent: "center", alignSelf:"center", backgroundColor: "rgba(255, 255, 255, 0.4)",
		}}>
				<h1> Time for Kids</h1>
                    <p style= {{textAlign:"left"}} > A section of Time magazine made especially for kids. You can search for articles by grade level, topic, and more! </p>
                            <div className='continueButton' style= {{backgroundColor: "#14A6A6" }}>
                            <a href="https://www.timeforkids.com/" target="_blank" >Head to <b>Time For Kids</b></a>
            			</div>
		</div>
		<div style = {{padding: "2%"}}></div>
		</div>
		</div>
		</div>
	);
}

export default News_for_kids;