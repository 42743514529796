import React from 'react';
import {Link} from 'react-router-dom';
import { animated } from "react-spring";
import "./menu.css";
import { gsap } from "gsap";
import { TweenLite, Elastic, Bounce } from "gsap";

export default  function DeskTopNav() {
    return (
			
        <div class="topnav" id="myTopnav">
        <a><Link to="../">
        <h3>HOME</h3>
        </Link></a>
        <a>  <div class="dropdown">
          
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a>< Link to="/pages/learning_home"> <h3> INTERACTIVE READING </h3></Link></a>
          </button>
          
          <div class="dropdown-content" >
          <p style = {{textAlign: "left", fontSize: "1.2vw", color: "black",  marginLeft: "5%", fontStyle: "italic" }}>Reading an interactive graphics "book" to learn the fundamentals.</p>

           <b> <Link to="/pages/learning_home" >START HERE </Link></b>
            <Link to="/pages/first_page" > 1) What is the news?</Link>
            <Link to="/pages/second_page" onclick="dropDown()"> 2) Who tells us the news?</Link>
            <Link to="/pages/third_page"> 3) Why is the news important?</Link>
            <Link to="/pages/fourth_page"> 4) How is the news made?</Link>
            <Link to="/pages/fifth_page"> 5) Where can I find the news?</Link>
            <Link to="/pages/sixth_page"> 6) How do I tell if information is real?</Link>
            <Link to="/pages/seventh_page"> 7) What is media bias?</Link>

          </div>
        </div> </a>

        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a><Link to="/pages/social_media_guide"> <h3>SOCIAL MEDIA GUIDE</h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", fontSize: "1.2vw", marginLeft: "5%", marginRight: "5%"}}>The go-to, realistic guide for navigating social media as a tween in 2022!</p>
          </div>
        </div>
        
        


        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
         <a>< Link to="/pages/quiz_game_1"> <h3>ULTIMATE QUIZ</h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", textAlign: "left", fontSize: "1.2vw",  marginLeft: "5%", marginRight: "5%"}}>Have you finished the modules and social media guide? Do you believe you're a media master? Put your skills to the test in the Ultimate Quiz!</p>
          </div>
        </div>

        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a><Link to="/pages/resources"> <h3>NEWSPAPER LOCATOR 3000</h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", fontSize: "1.2vw", marginLeft: "5%", marginRight: "5%"}}>Find your local paper in this interactive map and searchable database of newspapers!</p>
          </div>
        </div>

        

        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a><Link to="/pages/activities"> <h3>ACTIVITIES </h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", textAlign: "left", fontSize: "1.2vw",  marginLeft: "5%", marginRight: "5%"}}>Fun, in-person activities to make you think, report, and write like a journalist!</p>
          </div>
        </div>

        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a><Link to="/pages/news_for_kids"> <h3>NEWS FOR KIDS</h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", textAlign: "left", fontSize: "1.2vw",  marginLeft: "5%", marginRight: "5%"}}>Fascinating news sources and articles that are kid-friendly!</p>
          </div>
        </div>


        <div class="dropdown">
        <a>
          <button id = "myDropdown" class="dropbtn" onclick="dropDown()" >
          <a><Link to="/pages/About"> <h3>ABOUT</h3></Link></a>
          </button>
          </a>
          <div class="dropdown-content" >
          <p style = {{fontStyle: "italic", fontSize: "1.2vw", marginLeft: "5%", marginRight: "5%"}}>About the toolkit and its creator.</p>
          </div>
        </div>

        
       

          </div>

        
        );
  }

