import React from 'react';

/* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
import { useCurrentWidth } from "react-breakpoints-hook";

import MobileNav from "./MobileNavBar/MobileNav";
import DeskTopNav from "./DeskTopNavBar/DeskTopNav";

export default function NavBar() {
  const width = useCurrentWidth();

  if (width > 1200) {
    return <DeskTopNav />;
  }

  return <MobileNav />;
}


