import React from 'react'
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import "./App.css";
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@mui/material/Card';
import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'
import SplitText from 'react-pose-text';
import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "./VisabilitySensor";

import { Spring, useSpring } from 'react-spring';
import ScrollToTop from './ScrollToTop';

import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './App.css';

import gsap from "gsap";
import { TweenLite } from "gsap";

import { HashRouter } from 'react-router-dom'




ReactDOM.render(
  <HashRouter>
  <BrowserRouter>
      <ScrollToTop />
    <App/>
  </BrowserRouter>
  </HashRouter>,
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
