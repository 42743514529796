import {Link} from 'react-router-dom';
import React from "react"
import Fifth_page from "./fifth_page"

import { Fade, Button } from "shards-react";
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";
import RedCelebrationSquiggles from "./images/RedCelebrationSquiggles.png";
import { textAlign } from '@mui/system';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";
import {ReactComponent as UpsidedowntriangleMINT} from "./images/MintPictures/UpsidedowntriangleMINT.svg";
import {ReactComponent as QuestionMINT} from "./images/MintPictures/QuestionMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";


import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";
import {ReactComponent as CoworkersDARKBLUE} from "./images/DarkBluePictures/Co-workersDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";
import {ReactComponent as QuestioncomputerBLUE} from "./images/LightBluePictures/QuestioncomputerBLUE.svg";


import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";
import {ReactComponent as CollaboratorsORANGE} from "./images/OrangePictures/CollaboratorsORANGE.svg";






const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%",

  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	marginLeft: "15%",
	marginRight:"15%",
  };




function Fourth_page(){
	return (
		<div style= {{	backgroundImage: `url(${RedCelebrationSquiggles})`,
		backgroundSize: 'cover',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: "75%",
	  height: "100vh"}} >
			<Carousel style={{
				 
				width: "100vh",
				overflow: "hidden",
				}}

					plugins={['arrows', 'fastSwipe', 'clickToChange']}>
		  <div
			style={{
			  ...centeredStyles,
			   
			}}
		  >
			<VisibilitySensor>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					 <h1 style = {{fontFamily: 'Roboto'}}>4) How is the news made?</h1>
					<p>Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)</p>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
			<div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
		
			<VisibilitySensor>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<TypewriterRED class="mediumImage"/>

					<h2>Journalists follow a specific process in order to publish a news story.</h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>A news story begins with an <i>idea</i> or <i>new piece of information</i>. </h2>
					<NewIdeasMINT class="mediumImage"/>

					</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>Like a new mayor getting elected or a big investigation.</h2>
					<PoliticsVotingRED class= "mediumImage" style={{textAlign:"right"}}/>

					</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>	A journalist starts by figuring out these key points:</h2>
				<h3><ul style = {{textAlign: "left"}}>
					<li>
						<b>What is the story about?</b>
					</li>
					<li>
					<b>	When and where did this happen?</b>
					</li>
					<li>
						<b> Who is involved? </b>
					</li>
					<li>
						<b> How did this happen? </b>
					</li>	
				</ul></h3>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			 
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<HappyfriendsBLUE class="mediumImage"/>
					<h2>From there, a journalist will <i>interview </i>people who know about or are a part of the news. </h2>

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			 
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>Journalists will ask <i>objective</i>, or <i>fair</i>, questions to use in the news story.  </h2>
				<TypingfocusMINT class="mediumImage"/>
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			 
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>

				<h2>A journalist can interview <i>any number</i> of people for a story. </h2>
\				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		<div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>The more <i>sources</i>, or <i>people interviewed</i>, the better the story can potentially be.</h2>
					<TeamofpeopleRED class="mediumImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>When writing, journalists begin with the most important information at the top. From there, they will try to answer all the questions that someone might have. </h2>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
 				<h2>	This kind of writing looks like an <i>upside-down pyramid</i>. </h2>
				 <UpsidedowntriangleMINT class="mediumImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>

 					<h2>It's important for journalists to <i>attribute</i>, or <i>give credit</i>, to people they've interviewed or are using information from. </h2>
					 <ExpertsRED class="smallImage"/>

				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<ReadingbookBRIGHTMINT class="smallImage"/>
					<VideowatchingDARKBLUE class="smallImage"/>

					<h2> This is so the person seeing the news knows the information is accurate and honest.</h2>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<CollaboratorsORANGE class="mediumImage"/>
				<h2>	Once finished writing, a journalist will give their story to their <i>editor</i>.  </h2>            
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2><i>Editors</i> are very experienced journalists. </h2>
				<EducationMINT class="mediumImage"/>

				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>Editors make sure the other journalists are doing the best reporting and writing they possibly could be.</h2>
				<CoworkersDARKBLUE class="mediumImage"/>

				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
			<h2>An editor checks over the news, makes changes to the writing, and asks questions about the information. </h2>
				<QuestionMINT class="smallImage"/>
				<QuestioncomputerBLUE class="smallImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>	After an editor approves a news story, the story is often sent to <i>more</i> people for even <i>more</i> checking. </h2>
				<WalldrawingORANGE class="mediumImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>	The goal is to make sure everything is accurate and truthful. </h2>

				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<NewspaperReadingLIGHTBLUE class="smallImage"/>
				<h2>	Once everything is approved, the news story can then be published and read by everyone in the public. </h2>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<h2>	Journalists are regular people, just like you and me, and sometimes journalists make mistakes. </h2>
				<SadORANGE class="smallImage"/>

				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>If this happens, journalists will <i>fix the mistake and explain what happened</i>. </h2>
					<TypingfocusMINT class="smallImage"/>
					<CollaboratorsORANGE class="smallImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>Mistakes don't happen too often because of all the checking and editing,</h2>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<HappyloveBLUE class="mediumImage"/>

					<h2>but journalists should always be honest about when mistakes happen. </h2>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<h2>This is how journalists, too, take accountability!</h2>
					<HappycelebrateLIGHTBLUE class="mediumImage"/>
				</div>
			  )}
			  
			</VisibilitySensor>
		  
		  </div>
		  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					className={isVisible ? "slideLeft enter" : "slideLeft"}
					style={{ ...h2Styles }}
				  >
					   <Link to="/pages/fifth_page" >
					<div className='continueButton'>
				 		Next
				  </div>
				  </Link>
				  
  
				  </div>
				  )}
				</VisibilitySensor>
			  </div>
			</Carousel>
		</div>
		
	  );
}

export default Fourth_page;