import React from "react"
import {Link} from 'react-router-dom';
import Fourth_page from "./fourth_page";

import { Fade, Button } from "shards-react";
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";
import DarkBlueCelebrationSquiggles from "./images/DarkBlueCelebrationSquiggles.png";

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";



import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";




import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";






const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%"
  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	marginLeft: "15%",
	marginRight:"15%",
  };


function Third_page(){
		return (
		<div style= {{backgroundImage: `url(${DarkBlueCelebrationSquiggles})`, backgroundSize: 'cover',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: "75%",
	  height: "100vh"}} >
			<Carousel style={{
				height: "100vh",
				width: "100vh",
				overflow: "hidden",
				}}

					plugins={['arrows', 'fastSwipe', 'clickToChange']}>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				}}
			  >
				<VisibilitySensor>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					  <h2 style = {{fontFamily: 'Roboto'}}>3) Why is the news important?</h2>
					  <ExpertsRED class="mediumImage"/>
					  <p>Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)</p>

					</div>
				  )}
				</VisibilitySensor>
			  </div>
			<div
				style={{
				  ...centeredStyles,
				   
				  overflow: "hidden",
				
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					  <h2 style={{paddingTop:"10%"}}>In the United States, we live in a <i>democracy</i>. </h2>
					  <VotingLIGHTBLUE class="medlargeImage"/>

					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  overflow: "hidden",
				
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<TeamofpeopleRED class="mediumImage" style= {{float:"left"}}/>
					  <h2>This means that every adult should have a voice in how our country is run. </h2>
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,	 
				}}
			  >
				<VisibilitySensor>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					 <h2>In our democracy, you have the right to <i>Freedom of Speech</i>. </h2>

					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				}}
			  >
				<VisibilitySensor>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					 <h2>	This is called <i>The First Amendment</i>. </h2>
					 <p>"Congress shall make no law respecting an establishment of religion, or prohibiting the free exercise thereof; or abridging the <b>freedom of speech, or of the press</b>; or the right of the people peaceably to assemble, and to petition the Government for a redress of grievances.

"</p>
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,	   
				  overflow: "hidden",
				  
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h3>Examples of Freedom of Speech, according to the United States Courts.</h3>
					
					<h3 >
					<ul style = {{textAlign: "left"}}>
					<li><b>The freedom not to speak</b>(specifically, the right not to salute the flag).
					 </li>

					<li><b>To use certain offensive words and phrases to convey political messages.</b>
					</li>

					<li><b>To contribute money (under certain circumstances) to political campaigns.</b>
					</li>

					<li><b>To engage in symbolic speech, (e.g., burning the flag in protest).</b>
					</li>
					</ul>
					</h3>
						</div>
				  )}
				</VisibilitySensor>
			  </div>
			  
			 
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
						<h2>In simpler terms, you can't get in big trouble for saying you prefer the color red over the color blue,</h2>
						<ColorRED class="smallImage"/>
						<ColorBLUE class="smallImage"/>
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
						<SuperheroORANGE class="mediumImage" />
					<h2>	Or if you prefer one superhero over another.</h2>
						
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					 <h2> Part of the First Amendment discusses <i>Freedom of The Press</i>. </h2>
	
					</div>
					
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2 style={{paddingTop:"10%"}}><i>The press</i> is a nickname for journalists. </h2>
					<NewspaperReadingLIGHTBLUE class="medlargeImage"/>
	
					</div>
					
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2>This freedom allows for the news to report on everything, both good and bad.</h2>
					<HappyloveBLUE class="mediumImage" style={{float:"left"}}/>
					<SadORANGE class="mediumImage"/>

	
					</div>
					
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2>  In a democracy, sometimes powerful people or groups don't do the right thing. </h2>
					</div>
					
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2> It's up to journalists to check in and report on unusual things that are happening.  </h2>
					<ThoughtprocessORANGE class="smallImage" style={{width:"20%"}}/>
					<WorkingremotelyMINT class="mediumImage"/>
					<ThoughtprocessORANGE class="smallImage" style={{width:"20%"}}/>
					</div>
					
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2>  Journalists are friends of democracy. </h2>
					<ManPointingAtNewsMINT class="smallImage"/>
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2>  They want to make sure you and I live in a fair, safe country. </h2>
					<CityDARKBLUE class="smallImage"/>
					<NeighborDARKBLUE class="smallImage"/>

					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					
					<h2>Writing about what's going on with everyone helps make sure that nobody is getting hurt and that our democracy is still strong.</h2>
					<HappyfriendsBLUE class="mediumImage"/>

					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					
					<h2>Writing about these things are important for <i>accountability</i>.</h2>
	
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<HappycelebrateLIGHTBLUE class="smallImage"/>
					<h2><i>Accountability</i> means to own up to one's mistakes or problems. </h2>
	
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					
					<h2> Accountability is important for everyone. Celebrities, teachers, politicians, athletes, and more. </h2>
					<EducationMINT class="smallImage"/> <ExpertsRED class="smallImage"/>
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<BlogpersonORANGE class="mediumImage"/>
					<h2>The news also writes about when people do the right thing and take accountability even when it is hard. </h2>
	
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				  
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					  className={isVisible ? "fadeIn enter" : "fadeIn"}
					  style={{ ...h2Styles }}
					>
					<h2>It takes journalists a lot of courage to ask the hard questions, but they do it for our democracy.</h2>
					
					</div>
				  )}
				</VisibilitySensor>
			  </div>
			  <div
				style={{
				  ...centeredStyles,
				   
				 
				  overflow: "hidden",
				}}
			  >
				<VisibilitySensor partialVisibility>
				  {({ isVisible }) => (
					<div
					className={isVisible ? "slideLeft enter" : "slideLeft"}
					style={{ ...h2Styles }}
				  >
					   <Link to="/pages/fourth_page" >
					<div className='continueButton'>
				 		Next
				  </div>
				  </Link>
				  
  
				  </div>
				  )}
				</VisibilitySensor>
			  </div>
			  </Carousel>
			</div>
			
			
		  );
		}
		

export default Third_page;