import {Link} from 'react-router-dom';
import React from "react";
import AmericaCelebrationSquiggles from "./images/AmericaCelebrationSquiggles.png";



function Game_2(){
	return(
		<iframe src="https://www.youtube.com/embed/cWDJoK8zw58"></iframe>

	);
}

export default Game_2;