import {Link} from 'react-router-dom';
import React, { createRef, useRef } from "react";
import { render } from "react-dom";

import MintGreenCelebrationSquiggles from "./images/MintGreenCelebrationSquiggles.png";



function Learning_home() {
  
  return (
    <div style = {{	backgroundImage: `url(${MintGreenCelebrationSquiggles})`, background: 'cover',
    display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "100%",
	overflowX: "hidden",
  backgroundSize: '60%'
    }}>
    <div style = {{padding: "2%"}}></div>
    <div style = {{ display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "70%",
	overflowX: "hidden",
  backgroundSize: '75%',
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  border: ".1em solid",
  borderRadius: "2%",
  padding: "2%"
  }}>
      <div style = {{textAlign: "center", paddingLeft: "10%", paddingRight: "10%"}}>
      <h1> LEARN ABOUT NEWS LITERACY </h1>
      <h2>Interactive graphic textbook </h2>
      <h3 style= {{textAlign:"left"}}> Below are links to different modules of the interactive textbook. 
        Each module teaches a different aspect about the news. Feel free to start in order, with module 1, or skip to any module you'd like.
        After reading through each module, check out the social media guide, and then head over to the quiz to test your media mastery.</h3>
      </div>

   
      <div style= {{padding: "1%"}}></div>
       <b> <p>Start Here!</p></b>
        <div className='continueButton'style = {{backgroundColor: "#D9580D"}}>
            <Link to="/pages/first_page" > 1) What is the news?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#F26835",}}>
            <Link to="/pages/second_page" >2) Who tells us the news?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#F2B47E", color : "#D9580D"}}>
           <Link to="/pages/third_page"> 3) Why is the news important?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#1B8C8C", color: "#64f7f7"}}>
            <Link to="/pages/fourth_page"> 4) How is the news made?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#14A6A6", color: "#64f7f7"}}>
            <Link to="/pages/fifth_page"> 5) Where can I find the news?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#64f7f7", color: "#1B8C8C"}}>
            <Link to="/pages/sixth_page"> 6) How do I tell if information is real?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>

        <div className='continueButton' style = {{backgroundColor: "#67FFE1", color: "#1B8C8C"}}>
            <Link to="/pages/seventh_page"> 7) What is media bias?</Link>
        </div>
        <div style= {{padding: "1%"}}></div>
        </div>
        <div style = {{padding: "2%"}}></div>

    </div>
  );
}
export default Learning_home;