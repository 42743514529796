import React from 'react'
import Second_page from "./second_page";
import { Fade, Button } from "shards-react";
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";

import OrangeCelebrationSquiggles from "./images/OrangeCelebrationSquiggles.png";

import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";



import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";



import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";

import JoeyChestnut from "./images/OtherPictures/JoeyChestnutHeadline.png";





import Carousel, { Dots }  from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';



const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%",
  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	marginLeft: "10%",
	marginRight:"10%",

  };
  
  
 

function First_page() {

	
	return (

<div style= {{	backgroundImage: `url(${OrangeCelebrationSquiggles})`, background: 'cover',
    display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "100%",
	overflowX: "hidden",
  backgroundSize: "75%",
  height: "100vh"}} >
		<Carousel plugins={['arrows', 'fastSwipe', 'clickToChange']}>

		  <div id= {1}

			style={{
			  ...centeredStyles,
			  overflow: "hidden",
		
			}}
		  >
			 
			<VisibilitySensor>
			  {({ isVisible }) => (				
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<NewspaperReadingLIGHTBLUE style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%",
				float: "left",
				}}/>
				<ExpertsRED style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%"

				}}/>
				<ManPointingAtNewsMINT style={{
			  height: "30%",
			  width: "30%",
			  paddingLeft: "3%"

			}}/>
				  <h1 style = {{fontFamily: 'Roboto'}}>1) What is the News?</h1>
				  
				  
				</div>
				
			  )}
			</VisibilitySensor>
			<VisibilitySensor>
			  {({ isVisible }) => (
				<p
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				>
					Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)
				</p>
				
			  )}
			</VisibilitySensor>
			
		  </div>
			   <div id= {2}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			 
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<h2>
				  The news tells you and I what we need to know.
				  </h2>
				  <ManPointingAtNewsMINT style={{
			  height: "50%",
			  width: "50%",
			}}/>
			 <NewIdeasMINT style={{
			  height: "50%",
			  width: "50%",

			}}/>

			</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {3}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					 <ExpertsRED style={{
				paddingTop: "10%",
				height: "40%",
				width: "40%",
				alignSelf: "left",
				}}/>
				 <CityLocationsRED style={{
				paddingTop: "10%",
				height: "40%",
				width: "40%",
				alignSelf: "right",
				}}/>
				  <h2>The news is full of facts about people, places, and things going on.</h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {4}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2> The news covers all sorts of things.</h2>

				<NewspaperReadingLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				textAlign: "right",
				}}/>
				 <SharingArticlesLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				alignSelf: "left",
				float: "left",
				}}/>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>

		  <div id= {5}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					 <GlobeDARKBLUE style={{
				height: "60%",
				width: "90%",
				alignSelf: "left",
				float: "center",
				paddingBottom: "5%",
				

				}}/>
				 <h2> Whether it's happening around the globe, </h2>

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {6}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			  
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles, alignSelf: "center" }}
	
				>
				 <CityDARKBLUE class = "smallImage"/>
				 <h2> In your city,</h2>
				
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {7}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				  <h2>Or even next door.</h2>
				  <NeighborDARKBLUE class = "mediumImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {8}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<MusicRED class = "smallImage"/>
				<BasketballMINT class="smallImage"/>
				
				<h2>The news can be anything from music to sports, </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {9}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>From politics to education, </h2>
				<PoliticsVotingRED class= "mediumImage"/>
				<EducationMINT class = "mediumImage"/>
				</div>

			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {10}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<img src= {JoeyChestnut} style = {{overflow: "hidden",
					height: "40%",
					width: "110%",
					borderWidth: 3,
					borderColor: "red",
					borderRadius: "2em",}}/>
				<h2>And, sometimes, even a man who ate 76 hotdogs in 10 minutes.</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {11}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<NewspaperReadingLIGHTBLUE style={{
				height: "90%",
				width: "50%",
				textAlign: "right",
				float: "right"
				}}/>
			
				
				<h2>Because the news tells us what we should know.</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {12}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<Link to="/pages/second_page" >
				  <div className='continueButton'>
				  Next
				</div>
				</Link>
				

				</div>
		
			  )}
			</VisibilitySensor>
		  </div>
		  </Carousel >
		  <div>
    
    		</div>
		</div>
		
	  );

	
	}


export default First_page;

