import React from 'react'
import Second_page from "./second_page";
import { Fade, Button } from "shards-react";
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";

import DarkBlueCelebrationSquiggles from "./images/DarkBlueCelebrationSquiggles.png";
import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";
import {ReactComponent as UpsidedowntriangleMINT} from "./images/MintPictures/UpsidedowntriangleMINT.svg";
import {ReactComponent as QuestionMINT} from "./images/MintPictures/QuestionMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";


import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";
import {ReactComponent as CoworkersDARKBLUE} from "./images/DarkBluePictures/Co-workersDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";
import {ReactComponent as QuestioncomputerBLUE} from "./images/LightBluePictures/QuestioncomputerBLUE.svg";
import {ReactComponent as InformedFactBLUE} from "./images/LightBluePictures/InformedFactBLUE.svg";



import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";
import {ReactComponent as CollaboratorsORANGE} from "./images/OrangePictures/CollaboratorsORANGE.svg";

import OrlandoSentinel from "./images/OtherPictures/OrlandoSentinel.png";
import AJC from "./images/OtherPictures/ajc.jpg";
import BostonGlobe from "./images/OtherPictures/BostonGlobe.png";
import MiamiHerald from "./images/OtherPictures/MiamiHeraldWebsite.png";
import Fox31 from "./images/OtherPictures/Fox31TV.png";
import WaPoLogo from "./images/OtherPictures/WaPoLogo.png";
import NYTInstagram from "./images/OtherPictures/NYTInstagram.png";
import NewsForKidsLogo from "./images/OtherPictures/NewsForKidsLogo.png";
import NewsForKidsPage from "./images/OtherPictures/NewForKidsPage.png";
import ColumnsPage from "./images/OtherPictures/ColumnsPage.png";
import MediaBiasExample1 from "./images/OtherPictures/media_bias_example.png";







import Carousel, { Dots }  from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';



const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%",
  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	marginLeft: "10%",
	marginRight:"10%",

  };
  
  
 

function Seventh_page() {

	return (

<div style= {{	backgroundImage: `url(${DarkBlueCelebrationSquiggles})`, background: 'cover',
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   height: "10%",
   width: "100%",
   overflowX: "hidden",
 backgroundSize: "75%",
 height: "100vh"}} >
		<Carousel plugins={['arrows', 'fastSwipe', 'clickToChange']}>

		  <div id= {1}

			style={{
			  ...centeredStyles,
			  overflow: "hidden",
		
			}}
		  >
			 
			<VisibilitySensor>
			  {({ isVisible }) => (				
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<NewspaperReadingLIGHTBLUE style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%",
				float: "left",
				}}/>
				<ExpertsRED style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%"

				}}/>
				<ManPointingAtNewsMINT style={{
			  height: "30%",
			  width: "30%",
			  paddingLeft: "3%"

			}}/>
				  <h1 style = {{fontFamily: 'Roboto'}}>7) What is media bias?</h1>
				  
				  
				</div>
				
			  )}
			</VisibilitySensor>
			<VisibilitySensor>
			  {({ isVisible }) => (
				<p
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				>
					Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)
				</p>
				
			  )}
			</VisibilitySensor>
			
		  </div>
			   <div id= {2}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			 
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<h2>
               Since the internet and social media have so much content, it can be hard to decide whether a news outlet or article is biased.
				</h2>
				  <ManPointingAtNewsMINT style={{
			  height: "50%",
			  width: "50%",
			}}/>
			 <NewIdeasMINT style={{
			  height: "50%",
			  width: "50%",

			}}/>

			</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {3}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					 <QuestioncomputerBLUE class="mediumImage"/>
                   <h2>You might come across a post or story that refers to factual content, but the information is presented in an opinionated way.</h2> 
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {4}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2> It's important to understand the difference between factual, objective reporting and opinions presented as fact.</h2>

				<NewspaperReadingLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				textAlign: "right",
				}}/>
				 <SharingArticlesLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				alignSelf: "left",
				float: "left",
				}}/>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>

		  <div id= {5}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
		
				
				 <h2> Presenting information in a biased manner can either be very obvious or very hard to detect.</h2>
				 <ul style = {{textAlign: "left"}}>
				 	 <li><h4>Example of a mild bias: Imagine someone gets elected as president. Maybe one news outlet describes the candidate winning as "a grand victory" while another describes it as "an bad accident."
                          Both presented real information, but portrayed the same situaion in a different light</h4></li>
				</ul>
			
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {6}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				  <h2> A biased news headline looks like this: </h2>
					  <li> "The awful President Biden was elected, seems sketchy"</li>
					  <h2>And a factual, objective headline looks like this:</h2>
					  <li>"President Biden is elected, will start in January"</li>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {7}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
	
				<h2> When looking for media bias, here are some questions to ask yourself: </h2>
                <ul>
				 	 <li><h4>1) Is it presented as an opinion piece or as a news piece?</h4></li>
                      <li><h4>2) What sources are quoted or cited? Are they experts or professionals on the topic?</h4></li>
                      <li><h4>3) Are there any sides or perspectives missing? </h4></li>
                      <li><h4>4) What evidence, if any, is listed?</h4></li>


				</ul>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  
		  <div id= {8}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>Some people are quick to deem news pieces as "fake" if they don't like the facts within it.
				</h2>
                <PublishpersonORANGE class="smallImage"/>

                <InformedFactBLUE class="smallImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {9}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
		
                <HappyloveBLUE class="smallImage"/>
				<SadORANGE class="smallImage"/>
				
				<h2>But the truth is objective, and news can still very much be real even if someone doesn't like the content.  </h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {10}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				
				<h2> It's easy to listen to podcasters, celebities, and other influencers make claims, but it's important to deem whether or not they're telling the truth.</h2>
				<CollaboratorsORANGE class="smallImage"/>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {11}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
                <ThoughtprocessORANGE class="smallImage"/>

				<h2> A great source of recieving objective news is through local newspapers. 
				</h2>
			

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {12}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				

				<h2> True objective, journalists are taught a code of ethics and are heavily fact-checked before publishing a piece.
				</h2>
                <NewspaperReadingLIGHTBLUE 
				class="smallImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {13}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
		                <WalldrawingORANGE class="smallImage"/>

				<h2> It's vital to make opinions for one's self, but one must understand the importance of journalism in a democracy to do so. 
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {14}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				

				<h2> The free press, at its core, is necessary for a fair, structured country. Anyone who is strongly against the free press doesn't have people's best interests at heart.
				</h2>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {15}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
                <NewspaperReadingLIGHTBLUE class="mediumImage"/>

				<h2> Be critical of what you read, double-check your information, and always ask questions.
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {16}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
			
				<h2> This rule of logic applies to watching videos, listening to podcasts, and browsing social media.
				</h2>
                <h4>For example: A Minecraft youtuber is not a credible source of information for politics. </h4>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {17}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2> Bias is everywhere, and although nobody or no outlet is perfect, journalists can strive to achieve the most objective outlook possible for their readers' benefit.
				</h2>
				<HappycelebrateLIGHTBLUE class="mediumImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {18}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<h2>Congratulations! You've finished all of the chapters. Next, check out the "Social media guide" page and take the 
					"Ultimate Quiz" to check your mastery of the material. You can also head over to the "Newspaper Locater 3000", "Activities",
					or "News for kids" sections!
				</h2>
				<Link to="../" >
				  <div className='continueButton'>
			Return to the home page.
				</div>
				</Link>
				

				</div>
		
			  )}
			</VisibilitySensor>
		  </div>
		  </Carousel >
		  <div>
    
    		</div>
		</div>
		
	  );

	
	}


export default Seventh_page;

