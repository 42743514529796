import React from 'react'
import Second_page from "./second_page";
import { Fade, Button } from "shards-react";
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";

import AmericaCelebrationSquiggles from "./images/AmericaCelebrationSquiggles.png";
import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";
import {ReactComponent as UpsidedowntriangleMINT} from "./images/MintPictures/UpsidedowntriangleMINT.svg";
import {ReactComponent as QuestionMINT} from "./images/MintPictures/QuestionMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";


import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";
import {ReactComponent as CoworkersDARKBLUE} from "./images/DarkBluePictures/Co-workersDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";
import {ReactComponent as QuestioncomputerBLUE} from "./images/LightBluePictures/QuestioncomputerBLUE.svg";
import {ReactComponent as InformedFactBLUE} from "./images/LightBluePictures/InformedFactBLUE.svg";



import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";
import {ReactComponent as CollaboratorsORANGE} from "./images/OrangePictures/CollaboratorsORANGE.svg";

import OrlandoSentinel from "./images/OtherPictures/OrlandoSentinel.png";
import AJC from "./images/OtherPictures/ajc.jpg";
import BostonGlobe from "./images/OtherPictures/BostonGlobe.png";
import MiamiHerald from "./images/OtherPictures/MiamiHeraldWebsite.png";
import Fox31 from "./images/OtherPictures/Fox31TV.png";
import WaPoLogo from "./images/OtherPictures/WaPoLogo.png";
import NYTInstagram from "./images/OtherPictures/NYTInstagram.png";
import NewsForKidsLogo from "./images/OtherPictures/NewsForKidsLogo.png";
import NewsForKidsPage from "./images/OtherPictures/NewForKidsPage.png";
import ColumnsPage from "./images/OtherPictures/ColumnsPage.png";






import Carousel, { Dots }  from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';



const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%",
  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	marginLeft: "10%",
	marginRight:"10%",

  };
  
  
 

function Sixth_page() {

	return (

<div style= {{	backgroundImage: `url(${AmericaCelebrationSquiggles})`, background: 'cover',
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   height: "10%",
   width: "100%",
   overflowX: "hidden",
 backgroundSize: "75%",
 height: "100vh"}} >
		<Carousel plugins={['arrows', 'fastSwipe', 'clickToChange']}>

		  <div id= {1}

			style={{
			  ...centeredStyles,
			  overflow: "hidden",
		
			}}
		  >
			 
			<VisibilitySensor>
			  {({ isVisible }) => (				
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<NewspaperReadingLIGHTBLUE style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%",
				float: "left",
				}}/>
				<ExpertsRED style={{
				height: "30%",
				width: "30%",
				paddingLeft: "3%"

				}}/>
				<ManPointingAtNewsMINT style={{
			  height: "30%",
			  width: "30%",
			  paddingLeft: "3%"

			}}/>
				  <h1 style = {{fontFamily: 'Roboto'}}>6) How do I tell if information is real?</h1>
				  
				  
				</div>
				
			  )}
			</VisibilitySensor>
			<VisibilitySensor>
			  {({ isVisible }) => (
				<p
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				>
					Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)
				</p>
				
			  )}
			</VisibilitySensor>
			
		  </div>
			   <div id= {2}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			 
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<h2>
				It's important to make sure the <i><i>facts</i></i> you believe and share are real.
				</h2>
				  <ManPointingAtNewsMINT style={{
			  height: "50%",
			  width: "50%",
			}}/>
			 <NewIdeasMINT style={{
			  height: "50%",
			  width: "50%",

			}}/>

			</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {3}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					 <QuestioncomputerBLUE class="mediumImage"/>
				
				  <h2>It can be hard to tell what's real and not real, especially online.</h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {4}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>But don't worry! There are key ways to tell if information is <i>factual</i>.</h2>

				<NewspaperReadingLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				textAlign: "right",
				}}/>
				 <SharingArticlesLIGHTBLUE style={{
				height: "40%",
				width: "50%",
				alignSelf: "left",
				float: "left",
				}}/>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>

		  <div id= {5}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
		
				
				 <h2> <b>First</b>: check the source of the information. 	</h2>
				 <ul style = {{textAlign: "left"}}>
				 	 <li><h4>Is the account or website a verified news outlet?</h4></li>
					 <li><h4>Is the user a journalist or certified expert in a field? </h4></li>
				</ul>
				<h2> If so, then it's probably real information. </h2>
			
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {6}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				  <h2> Next, check if the information is a <i>fact</i> or an <i>opinion</i>.</h2>
				  <BlogpersonORANGE class = "mediumImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {7}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
	
				<h2> A <i>fact</i> will be worded in a non-biased way with proof of where the information came from. Always 
					look for attributions and citations when you examine anything that claims to be a <i>fact</i>. </h2>
					<PublishpersonORANGE class="smallImage"/>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  
		  <div id= {8}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<InformedFactBLUE class="mediumImage"/>
				<h2>In news articles, <i>facts</i> are always attributed to a source of information. <i>facts</i> often include numbers and statistics.
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {9}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
		
			
				
				<h2>If a <i>fact</i> seems too crazy or too good to be true, double-check with a second source to verify the information. </h2>
				<CollaboratorsORANGE class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {10}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<HappyloveBLUE class="smallImage"/>
				<SadORANGE class="smallImage"/>
				
				<h2> An <i>opinion</i> means it is someone's thoughts on a topic. <i>opinions</i> have biased and strongly worded language such as "I love" or "I hate." </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {11}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
			
				<h2> <i>opinions</i> can be found in quotations in news articles. Journalists often cite the <i>opinions</i> of experts or
					relevant people to the news story to add context.
				</h2>
				<NewspaperReadingLIGHTBLUE 
				class="smallImage"/>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {12}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				

				<h2> Remember: An <i>opinion</i> is not a <i>fact</i>. It's important to form your own <i>opinions</i> based on <i>facts</i>.
				</h2>
				<ThoughtprocessORANGE class="smallImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {13}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					<img src={ColumnsPage} style={{height:"40%", width:"80%"}}/>
				<h2> Some articles are <i>opinion</i> pieces, which are also called <i>columns</i>, <i>commentary</i>, or even <i> editorials</i>. These pieces mean that the writer offers an <i>opinion</i>.
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {14}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
			
				<h2> <i>opinion</i> pieces can add perspective to a <i>factual</i> news piece, but it does *not* replace
					the <i>factual</i> reporting and writing that news journalists do.
				</h2>
				<WalldrawingORANGE class="mediumImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {15}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
			
				<h2> This kind of thinking applies to social media, especially posts from friends and family
					that claim to have <i>facts</i> but don't include any sources or citations. 
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {16}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<NewspaperReadingLIGHTBLUE class="mediumImage"/>
			
				<h2> Always double-check your information! Whether it's a social media post, <i>opinion</i>, or news article.
				</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div id= {17}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2> You'll be a fact-checking, news expert in no time!
				</h2>
				<HappycelebrateLIGHTBLUE class="mediumImage"/>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div id= {18}
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<Link to="/pages/seventh_page" >
				  <div className='continueButton'>
					Next				
</div>
				</Link>
				

				</div>
		
			  )}
			</VisibilitySensor>
		  </div>
		  </Carousel >
		  <div>
    
    		</div>
		</div>
		
	  );

	
	}


export default Sixth_page;

