import {Link} from 'react-router-dom';
import React from "react"
import { render } from "react-dom";
import Accordion from "../Accordion";

import RedCelebrationSquiggles from "./images/RedCelebrationSquiggles.png";

function For_theeducators(){
    return(
        <div style={{	
          background: 'cover',
          display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "10%",
        width: "100%",
        overflowX: "hidden",
        backgroundSize: "75%",
            backgroundImage: `url(${RedCelebrationSquiggles})`,
            }}>
        <div style = {{padding: "2%"}}></div>
<div style = {{ display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "80%",
	overflowX: "hidden",
  backgroundSize: '75%',
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  border: ".1em solid",
  borderRadius: "2%",
  padding: "2%",
  marginRight: "8%",
  marginLeft: "9%"

  }}>        <h1> FAQ</h1>
       <b> <h2>Why learn news literacy?</h2></b>
        <p>
					Journalism, at its core, should be viewed as the fourth branch of government. The news, particularly local, ensures that
					every member of a community and society is being held accountable. If children are able to understand basic civics and federal government, 
					then they should understand the function and importance of a free press.
				  </p>
				  <p>
					News literacy, just like civic literacy, begins at an early age. It's especially important to teach students
					to be able to digest information and spot false news with the rise of social media and the unfortunate 
					degradation of news outlets across the country.
				  </p>
        <div style = {{padding: "2%"}}></div>
        
      <b> <h2>Why use The News Literacy Toolkit</h2></b>
				  <p>
					The News Literacy Toolkit is meant to supplement the teaching of journalism and civics. View these resources
					similar to other multimedia educational games and tools. The content is meant to engage and encourage a child's
					understanding of journalism.
				  </p>
           
          </div>
          <div style = {{padding: "10%"}}></div>

      </div>
    );
}
export default For_theeducators;