import React from "react";
import {Link} from 'react-router-dom';
import Sixth_page from "./sixth_page"

import { Fade, Button } from "shards-react";
import Card from '@mui/material/Card';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";
import MintGreenCelebrationSquiggles from "./images/MintGreenCelebrationSquiggles.png";

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";
import {ReactComponent as UpsidedowntriangleMINT} from "./images/MintPictures/UpsidedowntriangleMINT.svg";
import {ReactComponent as QuestionMINT} from "./images/MintPictures/QuestionMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";


import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";
import {ReactComponent as CoworkersDARKBLUE} from "./images/DarkBluePictures/Co-workersDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";
import {ReactComponent as QuestioncomputerBLUE} from "./images/LightBluePictures/QuestioncomputerBLUE.svg";


import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";
import {ReactComponent as CollaboratorsORANGE} from "./images/OrangePictures/CollaboratorsORANGE.svg";

import OrlandoSentinel from "./images/OtherPictures/OrlandoSentinel.png";
import AJC from "./images/OtherPictures/ajc.jpg";
import BostonGlobe from "./images/OtherPictures/BostonGlobe.png";
import MiamiHerald from "./images/OtherPictures/MiamiHeraldWebsite.png";
import Fox31 from "./images/OtherPictures/Fox31TV.png";
import WaPoLogo from "./images/OtherPictures/WaPoLogo.png";
import NYTInstagram from "./images/OtherPictures/NYTInstagram.png";
import NewsForKidsLogo from "./images/OtherPictures/NewsForKidsLogo.png";
import NewsForKidsPage from "./images/OtherPictures/NewForKidsPage.png";





import {ReactComponent as UsaBLACK} from "./images/OtherPictures/usaBLACK.svg";






const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%"
  };
  
  const h2Styles = {
	fontSize: "120%",
	alignItems: "center",
	justifyContent: "center",
	marginLeft: "15%",
	marginRight:"15%",
  };



function Fifth_page(){
	return (
		<div style= {{		backgroundImage: `url(${MintGreenCelebrationSquiggles})`,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: "75%",
	  height: "100vh"}} >
			<Carousel style={{
				 
				width: "100vh",
				overflow: "hidden",
				}}

					plugins={['arrows', 'fastSwipe', 'clickToChange']}>
		  <div

			style={{
			  ...centeredStyles,
			   

			}}
		  >
			<VisibilitySensor>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
                  <h1 style = {{fontFamily: 'Roboto'}}> 5) Where can I find the news?</h1>
				  
				</div>
				
			  )}
			</VisibilitySensor>
			<VisibilitySensor>
			  {({ isVisible }) => (
				<p
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				>
					Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)
				  
				</p>
				
			  )}
			</VisibilitySensor>
			
		  </div>
			   <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			  
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
                <h2> You can find the news in all sorts of places.</h2>
				<CityLocationsRED class="mediumImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					<NewspaperReadingLIGHTBLUE class="smallImage"/>
					<BlogpersonORANGE class="smallImage"/>
                  <h2>  You can read it in a newspaper or on a website.</h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideUp enter" : "slideUp"}
				  style={{ ...h2Styles }}
				>
                 <h2>   You can watch it on the television or on a tablet and phone.</h2>
				 <VideowatchingDARKBLUE class="smallImage"/>
				 <VideographerRED class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<ListeningheadphonesRED class="smallImage"/>
                  <h2>  You can even listen to it on the radio or on a podcast.         </h2>        

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
                   <h2> Technology allows for more people to see and find the news faster. </h2>
				   <QuestioncomputerBLUE class="mediumImage"/>
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					<PublishpersonORANGE class="mediumImage"/>
                  <h2>  Different news publications <i>publish</i>, or <i>tell us</i>, about different kinds of news. </h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>	There are journalists who report news only on a specific, smaller region or city. They are called <i>local journalists</i>. </h2>
				<NeighborDARKBLUE class="smallImage"/>
				<CityLocationsRED class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>

				<img src= {AJC} class="smallImage"/>
				<img src= {OrlandoSentinel} class="smallImage"/>

		
				<h2> Their news gets published, or seen, in companies with the city's name in the title. </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
								<img src= {MiamiHerald} class="smallImage"/>
								<img src= {Fox31} class="smallImage"/>
				<h2>You can find this news online, on local television stations, and even at certain stores. </h2>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>Quick question: <i>Do you know the names of your local newspaper and news station?</i></h2>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>  Other journalists write about news that's happening across the country. </h2>
				<UsaBLACK class="smallImage"/>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>They usually cover specific topics, like politics in Washington D.C., the capitol of the United States.</h2>
				<img src= {WaPoLogo} class="smallImage"/>
				<PoliticsVotingRED class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<GlobeDARKBLUE class="mediumImage"/>
				<h2> Some journalists even write about what's happening in different countries! </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>All of this news can also be found online, on national television channels, and in printed newspapers.</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
                <h2>Journalists also post the news on social media. </h2>
<img src={NYTInstagram} style={{textAlign:"right", height:"80%", width:"60%"}}/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>It's important to make sure the news you read online is coming from a real, verified news account and not a made-up, or fake, one. </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>  Don't worry! We will learn how to check if information is real or not later on. </h2>
				<ManPointingAtNewsMINT class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
								<ReadingbookBRIGHTMINT class="smallImage"/>

				<h2>Not all news is kid-friendly, but there are places and companies who create news sections specifically for kids. </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<h2> There, you can find awesome news stories and videos about the cool things going on in the world. </h2>      
				<SuperheroORANGE class="smallImage"/>
				<BasketballMINT class="smallImage"/>
				
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>

				<h2>You can check out the <i>"News for kids"</i> and <i>"Social Media Guide"</i> pages located in the Menu to learn more. </h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<Link to="/pages/sixth_page" >
				  <div className='continueButton'>
				Next
				</div>
				</Link>
				

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		</Carousel>
		</div>
		
		
	  );
}


export default Fifth_page;