import React from 'react'
import First_page from './first_page';
import {Link} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Button, Paper } from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";

import AmericaCelebrationSquiggles from "./images/AmericaCelebrationSquiggles.png";

import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";



const centeredStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "100%",
	overflowX: "hidden",
	backgroundSize: '100%',
	backgroundImage: `url(${AmericaCelebrationSquiggles})`,

  };

  const h2Styles = {
	fontSize: "100%",
	alignItems: "center",
	justifyContent: "center",
	textAlign:"center",
	marginLeft: "auto",
	marginRight:"auto",
  };

function Home(){
	return(
	
	<div>
	<div style={{	
		backgroundSize: '70%',
		backgroundImage: `url(${AmericaCelebrationSquiggles})`,
		}}>
<div style= {{padding: "2%"}}></div>
<div style = {{ display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "90%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "3%",
	marginright: "5%",
	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
  }
  }>
			<div>
			<h1 style= {{fontSize: "400%"}}>The News Literacy Toolkit</h1>
			<b><h2> Teaching the next generation of informed news consumers</h2></b>

			<h4><i> A journalism and media curriculum for fifth graders</i> </h4>
			
		
			  	<NewspaperReadingLIGHTBLUE style={{
				height: "20%",
				width: "30%",
				float: "center",
				}}/>
		
				  
				  
			</div>
			<div style= {{padding: "5%"}}></div>


	<table class= "table_main" style= {{textAlign: "center",float: "center", width: "90%",  marginLeft: "auto", marginRight: "auto", height:"800%", display: "table", verticalAlign:"middle"}}>		
	<div style = {{display:"table-row", height: "70%",  width: "50%", paddingBottom: "10%" }}>
				<div style = {{overFlow: "hidden", float: "left", justifyContent: "left",  width: "35%",  height: "100%", display:"table-cell", top:"0", backgroundColor: "rgba(14, 49, 153, 0.5)",
			 borderRadius: "3%", border: ".1em solid",padding:"5%", boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
			}}>
					
					<Link to="/pages/Learning_home" style = {{color:"black", textDecoration:"none"}} >
							<div className='introButton'>
							<h4 style={{color:"black"}}>INTERACTIVE READING</h4>
							</div>
							</Link>

							<div style= {{padding: ".5%"}}></div>

							<h3 style= {{textAlign:"left"}}>Seven interactive modules to teach the essentials of news literacy.</h3>
				</div>
				


				<div style = {{overFlow: "hidden", float: "right", justifyContent: "right",  width: "35%", height: "100%",  display:"table-cell", backgroundColor:"rgba(224, 20, 20, 0.5)", borderRadius: "3%", border: ".1em solid", padding: "5%", 	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
}}>
						<Link to="/pages/social_media_guide" >
								<div className='introButton'>
								<h4 style={{color:"black"}}><b>SOCIAL MEDIA GUIDE</b></h4>
							</div>
							</Link>	
							<div style= {{padding: ".5%"}}></div>

							<h3 style= {{textAlign:"left"}}> A practical, interactive guide to navigating the internet. </h3>

				</div>
	</div>

	<div style= {{padding: "2%", tableLayout: "fixed", height: "40%", }}></div>

			
	<div style = {{display:"table-row",  height: "70%",  width: "50%",  }}>
				<div style = {{ overFlow: "hidden",float: "left", 	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)", 
justifyContent: "left",  width: "35%", height: "100%",   display:"table-cell",backgroundColor:"rgba(224, 20, 20, 0.5)", borderRadius: "3%", border: ".1em solid", padding: "5%"}}>
					
					
					<Link to="/pages/resources" >
							<div className='introButton'>
							<h4 style={{color:"black"}}> NEWSPAPER LOCATER 3000</h4>
							</div>
							</Link>

							<div style= {{padding: ".5%"}}></div>

							<h3 style= {{textAlign:"left"}}>Interactive map and searchable database of local newspapers across the nation.</h3>
				
				</div>
			

				<div style = {{	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)", overFlow: "hidden",
float: "right", justifyContent: "right", width: "35%", height: "100%",  display:"table-cell",backgroundColor:"rgba(14, 49, 153, 0.5)", borderRadius: "3%", border: ".1em solid", padding: "5%"}}>
					
					<Link to="/pages/quiz_game_1" >
							<div className='introButton'>
							<h4 style={{color:"black"}}>THE ULTIMATE QUIZ</h4>
							</div>
							</Link>
							<div style= {{padding: ".5%"}}></div>

							<h3 style= {{textAlign:"left"}}> Are you a news literacy expert? Find out by taking the Ultimate Quiz!</h3>

				</div>
					
		</div>
		<div style= {{padding: "2%"}}></div>

		<div style = {{display:"table-row",  height: "70%",  width: "50%"}}>
				<div style = {{overFlow: "hidden", float: "left", 	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)", 
justifyContent: "left",  width: "35%", height: "100%",  display:"table-cell",backgroundColor:"rgba(14, 49, 153, 0.5)", borderRadius: "3%", border: ".1em solid", padding: "5%"}}>
					<Link to="/pages/Activities" >
						<div className='introButton'>
						<h4 style={{color:"black"}}> IN-PERSON ACTIVITIES</h4>
						</div>
						</Link>
						<div style= {{padding: ".5%"}}></div>

						<h3 style= {{textAlign:"left"}}> In-person activities to immerse one's self into journalism.</h3>

				</div>
				
				
			

				<div style = {{	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)", overFlow: "hidden",
float: "right", justifyContent: "right", width: "35%", height: "100%",  display:"table-cell",backgroundColor:"rgba(224, 20, 20, 0.5)", borderRadius: "3%", border: ".1em solid", padding: "5%"}}>
						<Link to="/pages/news_for_kids">
						<div className='introButton'>
						<h4 style={{color:"black"}}>NEWS FOR KIDS</h4>
						</div>
						</Link>
						<div style= {{padding: ".5%"}}></div>

						<h3 style= {{textAlign:"left"}}> External links to news sources and reading. </h3>


				</div>
		</div>

	</table>				

	<div style= {{padding: "10%"}}></div>
	<Link to="/pages/About" >
			<div className='aboutButton' style = {{boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
}}>	
				<div style = {{textAlign: "center"}}> <h3 style={{color:"black"}}>About this project</h3></div> 
			</div>
			</Link>
	<div style= {{padding: "5%"}}></div>
		<h2 style= {{textAlign:"left"}}>Questions? Suggestions? Feel free to email the creator at danacassidy1@gmail.com</h2>
	</div>
	<div style= {{padding: "2%"}}></div>

	</div>


	</div>		


			
		
	);
}

export default Home;
