import {Link} from 'react-router-dom';
import React from "react"
import { render } from "react-dom";
import Accordion from "../Accordion";

import AmericaCelebrationSquiggles from "./images/AmericaCelebrationSquiggles.png";


	class Activities extends React.Component {
		constructor(props) {
		  super(props);
		  this.state = {
			selectedIndex: 0
		  };
		}
		render() {
		  return (
			<div style = {{	backgroundImage: `url(${AmericaCelebrationSquiggles})`, background: 'cover',
    display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "100%",
	overflowX: "hidden",
	backgroundSize: "50%",
    }}>
				<div style={{padding:"3%"}}></div>

	<div style = {{display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "80%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "0%",
	marginright: "20%"}}>
				<h1>ACTIVITIES</h1>
				<h2> Fun, engaging, in-person activities to help better understand the news.</h2>
				<h4> Click a divider to explore a new activity. </h4>
				
			
			  <p>
				
		
			  </p>
	  
			  <Accordion
				className="accordion"
				selectedIndex={this.state.selectedIndex}
				onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
			  >
				
				<div data-header="Activity 1: Research a topic!" className="accordion-item">
				
				
				<p>
				  1) Research a topic and have them find three credible news articles
				  from the last two years on said topic. Then, report, write, and present 
				  a summary about the most important, or “news worthy”, things that have happened within that topic in the past two years. 
				  Make sure every piece of information is attributed to a source and cited. This helps teach how to find and digest topics in an understandable, factual way.
				</p>

				
				</div>


				<div data-header="Activity 2: Conduct an interview!" className="accordion-item">
				

				<p>
				  2) Interview someone and write a 200 word summary about what you found out about the other person. 
				  Make sure to write out your questions for the other person. 
				  Examples of questions are:  What is your first and last name? How old are you? Where were you born? What is your favorite subject in school? What would you like to be when you grow up? Do you have any pets?
				</p>				  
				  
				</div>

				<div data-header="Activity 3: Report and write a news article!" className="accordion-item">
				
				
				<p>
				  3) Write a news article about an event or topic occurring within the classroom or at school. 
				  Stories can be a profile on a teacher, an event coming up on school grounds, how the school partook in a holiday like Earth Day, initiatives going on at the school, and more. 
				  Make sure each story has at least two people, or "sources", interviewed and quoted. This will teach you how to find stories, talk to sources, attribute information, and put it all together in a written piece. You can also choose to create a audio or video piece instead of writing!
				</p>

				  
				</div>

				<div data-header="Activity 4: Create a classroom newspaper!" className="accordion-item">
				
				
				<p>
			      4) Design a classroom newspaper! This can work in conjunction with other activities. You can work together with other people, or work alone, to design a small “paper” that consists of photos and news articles. You can also put together a “news cast” that compiles various videos of them presenting news segments about the classroom. This helps teach how journalists put together content, decide what is “news worthy”, and distribute it for audiences.
				</p>

				  
				</div>
				
			
				
			  </Accordion>
	  		<div style={{padding:"1%"}}></div>

	</div>	 
	<div style={{padding:"3%"}}></div>

			</div>
		  );
		}
	  }


export default Activities;