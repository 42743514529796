import {Link} from 'react-router-dom';
import React from "react";
import MintGreenCelebrationSquiggles from "./images/MintGreenCelebrationSquiggles.png";



function Quiz_game_1(){
	return(
		<div style = {{	backgroundImage: `url(${MintGreenCelebrationSquiggles})`, background: 'cover',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: '60%'
    }}>
		<div style = {{ display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "70%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	
	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
  }
  }>
		<h1> The Ultimate News Literacy Toolkit Quiz</h1>
		<p style={{textAlign:"left", marginLeft: "5%", marginRight:"5%"}}>Have you read through all the modules and social media guide? Test your expertise on
			news literacy, bias, and understanding of journalism in The Ultimate News Literacy Toolkit Quiz!
		</p>
		</div>
		<div style={{padding:"20%"}}></div>
		<iframe style={{ position:"absolute", width:"80%",  top: "70%", bottom:"0", height:"100% ", align: "center", }} src="https://www.educaplay.com/game/11028408-fact_or_opinion.html"></iframe>
		<div style={{padding:"100vh"}}></div>

	</div>
	);
}

export default Quiz_game_1;





