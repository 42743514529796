import {Link} from 'react-router-dom';
import React, { createRef, useRef } from "react";
import { render } from "react-dom";
import Quiz_game_1 from "./quiz_game_1";
import Game_2 from "./game_2";
import VisibilitySensor from "../VisabilitySensor";

import OrangeCelebrationSquiggles from "./images/OrangeCelebrationSquiggles.png";

import DarkBlueCelebrationSquiggles from "./images/DarkBlueCelebrationSquiggles.png";
import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";
import {ReactComponent as UpsidedowntriangleMINT} from "./images/MintPictures/UpsidedowntriangleMINT.svg";
import {ReactComponent as QuestionMINT} from "./images/MintPictures/QuestionMINT.svg";


import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";
import {ReactComponent as ColorRED} from "./images/RedPictures/ColorRED.svg";


import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";
import {ReactComponent as CoworkersDARKBLUE} from "./images/DarkBluePictures/Co-workersDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";
import {ReactComponent as VotingLIGHTBLUE} from "./images/LightBluePictures/VotingLIGHTBLUE.svg";
import {ReactComponent as ColorBLUE} from "./images/LightBluePictures/ColorBlue.svg";
import {ReactComponent as HappyloveBLUE} from "./images/LightBluePictures/HappyloveBLUE.svg";
import {ReactComponent as HappyfriendsBLUE} from "./images/LightBluePictures/HappyfriendsBLUE.svg";
import {ReactComponent as QuestioncomputerBLUE} from "./images/LightBluePictures/QuestioncomputerBLUE.svg";
import {ReactComponent as InformedFactBLUE} from "./images/LightBluePictures/InformedFactBLUE.svg";

import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";
import {ReactComponent as SuperheroORANGE} from "./images/OrangePictures/SuperheroORANGE.svg";
import {ReactComponent as SadORANGE} from "./images/OrangePictures/SadORANGE.svg";
import {ReactComponent as CollaboratorsORANGE} from "./images/OrangePictures/CollaboratorsORANGE.svg";

import OrlandoSentinel from "./images/OtherPictures/OrlandoSentinel.png";
import AJC from "./images/OtherPictures/ajc.jpg";
import BostonGlobe from "./images/OtherPictures/BostonGlobe.png";
import MiamiHerald from "./images/OtherPictures/MiamiHeraldWebsite.png";
import Fox31 from "./images/OtherPictures/Fox31TV.png";
import WaPoLogo from "./images/OtherPictures/WaPoLogo.png";
import NYTInstagram from "./images/OtherPictures/NYTInstagram.png";
import NewsForKidsLogo from "./images/OtherPictures/NewsForKidsLogo.png";
import NewsForKidsPage from "./images/OtherPictures/NewForKidsPage.png";
import ColumnsPage from "./images/OtherPictures/ColumnsPage.png";
import MediaBiasExample1 from "./images/OtherPictures/media_bias_example.png";



function Social_media_guide() {
  
  return (
<div style = {{	backgroundImage: `url(${OrangeCelebrationSquiggles})`, background: 'cover',
    display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "100%",
	overflowX: "hidden",
  backgroundSize: "75%"
    }}>
     
      <div style = {{padding: "2%"}}>
     
        </div>
    <div style = {{ display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "70%",
	overflowX: "hidden",
  backgroundSize: '75%',
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  border: ".1em solid",
  borderRadius: "2%",
  padding: "2%"
  }}>
  
      <div style = {{textAlign: "center", paddingLeft: "10%", paddingRight: "10%"}}>
  <h1> SOCIAL MEDIA GUIDE</h1>
  <p > Tips and tricks to navigating social media and online information (keep scrolling!)</p>

  <h2 style = {{textAlign: "left"}} > Social media is a giant placed filled with different platforms that provide endless
    content and information. 
  </h2>

  <h2 style = {{textAlign: "left"}}> This guide will offer suggestions and tips on how avoid falling down rabbit holes of
    wrong information and begin, or continue, to enjoy the awesome things that social media has to offer!
  </h2>

  <h2 style = {{textAlign: "left"}}> Let's get started!</h2>

<div>
<div >
			 
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				 <h1 style={{textAlign : "left"}}> Tip #1 </h1>
  <h2 style={{textAlign : "left"}}> When viewing a video or post taking a stance, check the sources and claims.</h2>
  <ExpertsRED class= "smallImage" style={{ 
				paddingTop: "10%",
				alignSelf: "right",
        float: "right"
				}}/>
 <h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}> Is there a citation for the information?</li>
    <li style={{padding: "2%"}}> Who posted the content? Are they an expert? Can you google them?</li>
    <li style={{padding: "2%"}}> Can you google the source of the information to see if it's a real
      place or trustworthy source? </li> 
    </ul></h3>

			</div>
			  )}
			</VisibilitySensor>
		</div>
  </div>
 

    <div style={{margin:"5%"}}></div>

<div>
    <VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				  <h1 style={{textAlign : "left"}}> Tip #2 </h1>
  <h2 style={{textAlign : "left"}}> Be skeptical of viral graphics, photos, and videos that claim to show outlandish "proof".</h2>
    <InformedFactBLUE class= "smallImage" style={{ 
				
				alignSelf: "right",
        float: "right"
				}}/>
<h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}> Who created the chart? Where is the data?</li>
    <li style={{padding: "2%"}}> Is the image the real one or edited? Try doing a reverse Google image search whenever an image goes viral.</li>
    <li style={{padding: "2%"}}> If it's a video, who uploaded it? If it's of a person, does it look real or edited?</li> 
    <li style={{padding: "2%"}}><b>Technology is advanced. It's always better to be skeptical.</b></li>
    </ul></h3>


			</div>
			  )}
			</VisibilitySensor>
    </div>

	
    <div style={{margin:"5%"}}></div>

    <VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				  <h1 style={{textAlign : "left"}}> Tip #3 </h1>
  <h2 style={{textAlign : "left"}}> Found a new creator you like? Do a search on their name or brand to find out more about them.</h2>
    <VideowatchingDARKBLUE class= "smallImage" style={{ 
				paddingTop: "10%",
				alignSelf: "right",
        float: "right"
				}}/>
 <h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}> Even if this person is just a gamer or influencer, who are they, really? </li>
    <li style={{padding: "2%"}}> Do they have a history of trouble, scandals, or lying?</li>
    <li style={{padding: "2%"}}> What are their beliefs?</li>
    <li style={{padding: "2%"}}> Do they talk a lot about social issues and politics? If so, what's their education and background?</li> 
    <li style={{padding: "2%"}}><b>After researching, ask yourself: Do you agree with their morals and beliefs? Is this someone who you believe is a good influence? Why?</b></li>
    </ul></h3>

			</div>
			  )}
			</VisibilitySensor>

      <div style={{margin:"5%"}}></div>


      <VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				  <h1 style={{textAlign : "left"}}> Tip #4 </h1>
  <h2 style={{textAlign : "left"}}> Follow verified (blue check mark) news accounts, reporters, and experts.</h2>
    <QuestioncomputerBLUE class= "smallImage" style={{ 
				paddingTop: "10%",
				alignSelf: "right",
        float: "right"
				}}/>
 <h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}> Reporters often "live tweet" news events in real time, giving you up to date facts. </li>
    <li style={{padding: "2%"}}> News outlets have employees dedicated to keeping Instagram, Facebook, Twitter, etc. updated</li>
    <li style={{padding: "2%"}}> The infographics that these verified experts and outlets post can be easy, and truthful, to share.</li>
    </ul></h3>

			</div>
			  )}
			</VisibilitySensor>
      <div style={{margin:"5%"}}></div>

      <VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				  <h1 style={{textAlign : "left"}}> Tip #5 </h1>
  <h2 style={{textAlign : "left"}}> Just because a celebrity or content creator says something doesn't make it true or OK.</h2>
    <CollaboratorsORANGE class= "smallImage" style={{ 
				paddingTop: "10%",
				alignSelf: "right",
        float: "right"
				}}/>
 <h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}><b>Did you know some influencers and celebrities have bigger followings than news outlets online?</b></li>
    <li style={{padding: "2%"}}> Don't always believe what your favorite creator says or does. Look into it, be skeptical, and ask questions.</li>
    <li style={{padding: "2%"}}> Creators, while very talented at what they do, don't always have the education or background to be making claims.</li>
    </ul></h3>

			</div>
			  )}
			</VisibilitySensor>
      <div style={{margin:"5%"}}></div>

      <div style={{padding:"5%"}}></div>


      <VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "slideLeft"}
				>
				  <h1 style={{textAlign : "center"}}> Quick internet safety tips!</h1>
  
<h3><ul style={{textAlign : "left", 	alignSelf: "left"}}>
    <li style={{padding: "2%"}}> 1) Try to not use your real name as much as possible, and if you do, keep your account on private with only friends and family following it. </li>
    <li style={{padding: "2%"}}> 2) Don't share or post things you wouldn't feel comfortable showing to a parent, teacher, or employer 10 years from now. Digital footprint is a very real thing. </li>
    <li style={{padding: "2%"}}> 3) Use a temporary email address that isn't attached to your personal information and identity to sign up for websites.</li>
    </ul></h3> 

			</div>
			  )}
			</VisibilitySensor>
  </div>

 
    <div style={{margin:"5%"}}></div>
    

<div>
    
		</div>
   
    <div style={{margin:"5%"}}></div>
  

    </div>
    </div>
  );
 
}
export default Social_media_guide;


