import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "./mobile.styles.css";

export default class MobileNav extends React.Component {
  state = {
    menuOpen: false
  };

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <Link onClick={() => this.closeMenu()} className="menu-item" to="../" >
        <h3 style = {{color: "white", alignText:"left"}}>HOME</h3>
        </Link>

        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/learning_home">
          <a> <h3 style = {{color: "white"}}> INTERACTIVE READING </h3></a>
        </Link>

        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/social_media_guide">
          <a><h3 style = {{color: "white"}}>SOCIAL MEDIA GUIDE</h3></a>        
        </Link>

        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/resources">
        <a><h3 style = {{color: "white"}}>NEWSPAPER LOCATOR 3000</h3></a>
        </Link>


        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/quiz_game_1">
          <a><h3 style = {{color: "white"}}>ULTIMATE QUIZ</h3></a>
        </Link>

    
        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/activities">
          <a><h3 style = {{color: "white"}}>IN-PERSON ACTIVITIES </h3></a>
        </Link>


        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/about">
        <a><h3 style = {{color:"white"}}>ABOUT</h3></a>
        </Link>

        <Link onClick={() => this.closeMenu()} className="menu-item" to="/pages/news_for_kids">
          <a> <h3 style = {{color: "white"}}> NEWS FOR KIDS </h3></a>
        </Link>
      
      
      </Menu>
    );
  }
}