import {Link} from 'react-router-dom';
import React from "react";
import axios from 'axios';


import LightBlueCelebrationSquiggles from "./images/LightBlueCelebrationSquiggles.png";
import AmericaCelebrationSquiggles from "./images/AmericaCelebrationSquiggles.png";

import DWChart from 'react-datawrapper-chart';



function Resources() {
  
    
     return (
      
    <div>
      <div style={{	
		backgroundImage: `url(${AmericaCelebrationSquiggles})`,
    display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		overflowX: "hidden",
		}}>



<div style = {{ display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	height: "10%",
	width: "70%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
  zIndex:"0",
	
	boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
  }
  }>
      <h1 style={{paddingTop:"5%", fontSize: "200%"}}> Newspaper Locater 3000</h1>
      <h3 style= {{textAlign:"left"}}>Find your local newspaper in the "Newspaper Locater 3000": An interactive map AND searchable dataset of outlets across the United States of America. Zoom in, explore, and have fun!</h3>
      <h3 style= {{textAlign:"left"}}>SCROLL DOWN TO SEE THE TABLE</h3>
      <b><h3 style= {{textAlign:"left"}}>NOTE: if you do not see your newspaper on the map, try searching for it in the table. Some outlets overlap one another due to being in the same city.</h3></b>
     <i> <h4 style= {{textAlign:"left"}}>Can't find it? Email danacassidy@ufl.edu for it to be added!</h4></i>
  </div>
  <div ></div>
  <DWChart style = {{maxWidth: "80%", paddingTop: "5%"}} title="Chart" src="https://datawrapper.dwcdn.net/KVct9/4/" />
 <DWChart style = {{maxWidth: "80%", paddingTop: "5%"}} title="Chart" src="https://datawrapper.dwcdn.net/GqFwn/3/"/>


<div style={{padding: "5%"}}></div>

  </div>


  
  </div>
      
 )
 };
 export default Resources;