import React from 'react';
import './App.css';

import Home from './pages/home';
import Learning_home from './pages/Learning_home';

import First_page from './pages/first_page';
import Second_page from "./pages/second_page";
import Third_page from "./pages/third_page";
import Fourth_page from "./pages/fourth_page";
import Fifth_page from "./pages/fifth_page";
import Sixth_page from "./pages/sixth_page";
import Seventh_page from "./pages/seventh_page";

import {Route, Link} from 'react-router-dom';
import NavBar from './NavBars/NavBar';

import Quiz_game_1 from "./pages/quiz_game_1";
import Game_2 from "./pages/game_2";
import Game_3 from "./pages/game_3";


import Games_home from "./pages/social_media_guide";
import News_for_kids from "./pages/news_for_kids";
import Activities from "./pages/Activities";
import For_theeducators from "./pages/for_theeducators";

import About from "./pages/About";
import Resources from "./pages/resources";

import Social_media_guide from "./pages/social_media_guide";




import { gsap } from "gsap";


import './App.css';

import { useState, useCallback } from 'react'
import { config, useSprings, animated } from 'react-spring'

import SplitText from 'react-pose-text';

import VisibilitySensor from "react-visibility-sensor";
import { Spring, useSpring } from 'react-spring'
import ScrollToTop from './ScrollToTop';
import { HashRouter } from 'react-router-dom'




function App() {


  const [rightMenuVisible, setRightMenuVisible] = useState(false);

  const rightMenuAnimation = useSpring({
    opacity: rightMenuVisible ? 1 : 0,
    transform: rightMenuVisible ? `translateX(0)` : `translateX(100%)`
  });
 
  return (
   
    
    <div>
      <div className="App">
      <style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  </style>
      
      
    <NavBar/>
    </div>


    <ScrollToTop />
      <Route exact path="/" component={Home} />
      <Route exact path="/pages/learning_home" component={Learning_home} />

      <Route exact path="/pages/first_page" component={First_page} />
      <Route exact path="/pages/second_page" component={Second_page} />
      <Route exact path="/pages/third_page" component={Third_page} />
      <Route exact path="/pages/fourth_page" component={Fourth_page} />
      <Route exact path="/pages/fifth_page" component={Fifth_page} />
      <Route exact path="/pages/sixth_page" component={Sixth_page} />
      <Route exact path="/pages/seventh_page" component={Seventh_page} />

    
      <Route exact path="/pages/quiz_game_1" component={Quiz_game_1} />
      <Route exact path="/pages/game_2" component={Game_2} />
      <Route exact path="/pages/game_2" component={Game_3} />


      <Route exact path="/pages/social_media_guide" component={Social_media_guide} />

      <Route exact path="/pages/Activities" component={Activities} />

      <Route exact path="/pages/news_for_kids" component={News_for_kids} />
      <Route exact path="/pages/for_theeducators" component={For_theeducators} />

      <Route exact path="/pages/About" component={About} />

      <Route exact path="/pages/resources" component={Resources} />


      </div>
  
    
  );

}

export default App;
