import React, { useState } from 'react';
import { Spring, useSpring } from 'react-spring'

import Third_page from "./third_page";
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';
import { Fade, Button } from "shards-react";
import SplitText from 'react-pose-text';
import { Scrollama, Step } from 'react-scrollama';
import VisibilitySensor from "../VisabilitySensor";
import LightBlueCelebrationSquiggles from "./images/LightBlueCelebrationSquiggles.png";

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {ReactComponent as ManPointingAtNewsMINT} from "./images/MintPictures/ManPointingAtNewsMINT.svg";
import {ReactComponent as NewIdeasMINT} from "./images/MintPictures/NewIdeasMINT.svg";
import {ReactComponent as BasketballMINT} from "./images/MintPictures/BasketballMINT.svg";
import {ReactComponent as EducationMINT} from "./images/MintPictures/EducationMINT.svg";
import {ReactComponent as ReadingbookBRIGHTMINT} from "./images/MintPictures/readingbookBRIGHTMINT.svg";
import {ReactComponent as WorkingremotelyMINT} from "./images/MintPictures/workingremotelyMINT.svg";
import {ReactComponent as TypingfocusMINT} from "./images/MintPictures/TypingfocusMINT.svg";




import {ReactComponent as ExpertsRED} from "./images/RedPictures/ExpertsRED.svg";
import {ReactComponent as CityLocationsRED} from "./images/RedPictures/CityLocationsRED.svg";
import {ReactComponent as MusicRED} from "./images/RedPictures/MusicRED.svg";
import {ReactComponent as PoliticsVotingRED} from "./images/RedPictures/PoliticsVotingRED.svg";
import {ReactComponent as ListeningheadphonesRED} from "./images/RedPictures/listeningheadphonesRED.svg";
import {ReactComponent as TeamofpeopleRED} from "./images/RedPictures/TeamofpeopleRED.svg";
import {ReactComponent as TypewriterRED} from "./images/RedPictures/TypewriterRED.svg";
import {ReactComponent as PhotographerRED} from "./images/RedPictures/PhotographerRED.svg";
import {ReactComponent as VideographerRED} from "./images/RedPictures/VideographerRED.svg";







import {ReactComponent as GlobeDARKBLUE} from "./images/DarkBluePictures/GlobeDARKBLUE.svg";
import {ReactComponent as CityDARKBLUE} from "./images/DarkBluePictures/CityDARKBLUE.svg";
import {ReactComponent as NeighborDARKBLUE} from "./images/DarkBluePictures/NeighborDARKBLUE.svg";
import {ReactComponent as VideowatchingDARKBLUE} from "./images/DarkBluePictures/VideowatchingDARKBLUE.svg";


import {ReactComponent as NewspaperReadingLIGHTBLUE} from "./images/LightBluePictures/NewspaperReadingLIGHTBLUE.svg";
import {ReactComponent as SharingArticlesLIGHTBLUE} from "./images/LightBluePictures/SharingArticlesLIGHTBLUE.svg";
import {ReactComponent as HappycelebrateLIGHTBLUE} from "./images/LightBluePictures/HappycelebrateLIGHTBLUE.svg";


import {ReactComponent as BlogpersonORANGE} from "./images/OrangePictures/blogpersonORANGE.svg";
import {ReactComponent as PublishpersonORANGE} from "./images/OrangePictures/publishpersonORANGE.svg";
import {ReactComponent as ThoughtprocessORANGE} from "./images/OrangePictures/thoughtprocessORANGE.svg";
import {ReactComponent as WalldrawingORANGE} from "./images/OrangePictures/walldrawingORANGE.svg";








const centeredStyles = {
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "75%",
	overflowX: "hidden",
	backgroundSize: '75%',
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	border: ".1em solid",
	borderRadius: "2%",
	padding: "2%",
	marginLeft: "10%",
	marginRight: "10%",
	height: "75vh",
	marginBottom: "5%",
	marginTop: "5%"
  };
  
  const h2Styles = {
	fontSize: "100%",
	alignItems: "center",
	justifyContent: "center",
	marginLeft: "10%",
	marginRight:"10%",
  };

function Second_page()
{
	return (

		<div style= {{backgroundImage: `url(${LightBlueCelebrationSquiggles})`,	backgroundSize: 'cover',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "10%",
		width: "100%",
		overflowX: "hidden",
	  backgroundSize: "75%",
	  height: "100vh"}} >
		<Carousel style={{
			  width: "100vh",
			  overflow: "hidden",
			  height: "100vh",

			}}

  				plugins={['arrows', 'fastSwipe', 'clickToChange']}>

		  <div
			style={{
			  ...centeredStyles,
			   
			 
			}}
		  >
			<VisibilitySensor>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				 <h1 style = {{fontFamily: 'Roboto'}}> 2) Who tells us the news?</h1>
				 <ExpertsRED class="mediumImage"/>
				  
				</div>
				
			  )}
			</VisibilitySensor>
			<VisibilitySensor>
			  {({ isVisible }) => (
				<p
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				>
					Tap the side arrows to navigate.
					(Pro tip: You can also swipe if you're on a phone or tablet!)				  
				</p>
				
			  )}
			</VisibilitySensor>
			
		  </div>
			   <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			 
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<BlogpersonORANGE class= "smallImage"/>
				<PublishpersonORANGE   class= "smallImage"/>

				<h2> The news is written and told to us by people called <i>journalists</i>.</h2>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			 
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					<SharingArticlesLIGHTBLUE class="mediumImage" style={{float:"right", }}/>
					<ThoughtprocessORANGE class="mediumImage" style={{alignSelf:"left", }}/>
				  <h2>Journalists do <i>reporting</i>, which means they find all of the facts and sources to put together the news. </h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			 
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>

				  <h2>Journalists see what's going on in society and summarize it for other people to read or watch.</h2>
				  <WalldrawingORANGE class="smallImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		 
		  <div
			style={{
			  ...centeredStyles,
			   
			  
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideUp enter" : "slideUp"}
				  style={{ ...h2Styles }}
				>
				<ReadingbookBRIGHTMINT class="smallImage" style={{width:"30%"}}/>
				<ListeningheadphonesRED class="smallImage" style={{width:"35%"}}/>
				<VideowatchingDARKBLUE class="smallImage" style={{width:"30%"}}/>
				<h2> A news story can be read, listened to, or watched. Journalists use all different kinds of tools to share the news.</h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				
				 <h2> Journalists are regular people like you and me. </h2>
				 <TeamofpeopleRED class="mediumImage"/>

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				<TypingfocusMINT class="smallImage" style={{float:"right"}}/>
				 <h2> They come from all kinds of backgrounds and have different interests.</h2>
				 <WorkingremotelyMINT class="mediumImage"  style={{float:"center"}}/>

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
					<EducationMINT class="smallImage" />
					<NewspaperReadingLIGHTBLUE class="smallImage"/>

				  <h2>Journalists report on all sorts of things and showcase the news in different ways.</h2>

				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			   
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "fadeIn enter" : "fadeIn"}
				  style={{ ...h2Styles }}
				>
				 <h2> Some appear on television, some create radio shows, some take photographs, and some write stories. </h2>
				 <VideographerRED class="smallImage"/>
				 <TypewriterRED class="smallImage"/>
				 <PhotographerRED class="smallImage"/>
				</div>
				
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				 <h2> Journalists aim to tell the truth and stick to the facts. </h2>
				 <SharingArticlesLIGHTBLUE class="mediumImage"/>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,

			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
					<BlogpersonORANGE class="mediumImage"/>
				 <h2> They want you to know what's actually going on. </h2>
				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				
				<h2>Without journalists, the world would be a very confusing place.</h2>
				<GlobeDARKBLUE class="mediumImage"/>

				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				  className={isVisible ? "slideLeft enter" : "slideLeft"}
				  style={{ ...h2Styles }}
				>
				<HappycelebrateLIGHTBLUE class="mediumImage" style={{padding:"2%"}}/>
				<h2>Maybe you, too, can become a journalist!</h2>


				</div>
			  )}
			</VisibilitySensor>
		  </div>
		  <div
			style={{
			  ...centeredStyles,
			  overflow: "hidden",
			}}
		  >
			<VisibilitySensor partialVisibility>
			  {({ isVisible }) => (
				<div
				className={isVisible ? "slideLeft enter" : "slideLeft"}
				style={{ ...h2Styles }}
			  >
				  <Link to="/pages/third_page" >
				<div className='continueButton'>
			  		Next
			  </div>
			  </Link>
			  

			  </div>
			  )}
			</VisibilitySensor>
		  </div>
		  </Carousel>
		</div>
		
		
	  );
}
	export default Second_page;